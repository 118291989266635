import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Modal from "./Modal";
import FeedbackForm from "./FeedbackForm";
import DeliveryLocationModal from "./Deliverylocation";
import axios from "axios";
import { SearchOutlined, MenuOutlined, CloseOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import ClubModal from "./Joinclub";
import PaymentModal from "./Payment";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import { message, Select } from "antd";
import Lottie from "lottie-react";
import deliverytruck from "./../../../assets/deliverytruck.json";
import phone from "./../../../assets/phone.json";
import { RxDashboard } from "react-icons/rx";
import { Card } from "@mui/material";

const Header = () => {
  const navigate = useNavigate();
  let showText = Cookies.get("AllTextHead");
  const encryptedToken = Cookies.get("token");
  const encryptedUser = Cookies.get("user");
  const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;

  if (!encryptedToken || !encryptedUser || !encryptionKey) {
    console.error("Missing encrypted data or encryption key");
  }
  // Decrypt the data
  const token = encryptedToken
    ? CryptoJS.AES.decrypt(encryptedToken, encryptionKey).toString(
      CryptoJS.enc.Utf8
    )
    : null;
  // const user = encryptedUser ? JSON.parse(CryptoJS.AES.decrypt(encryptedUser, encryptionKey).toString(CryptoJS.enc.Utf8)) : null;

  // console.log("user==>",user)

  const [showDropdown, setShowDropdown] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const clubModalSkipped = localStorage.getItem("clubModalSkipped") === "true";
  // console.log(clubModalSkipped, "clubModalSkipped");
  const [Data, setData] = useState([]);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const totalItems = useSelector((state) => state.cart?.uniqueItems);
  const totalItems1 = useSelector((state) => state.cart1?.uniqueItems);
  const total = totalItems + totalItems1;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [categoryMap, setCategoryMap] = useState({});

  // const encryptedUser = Cookies.get('user');
  const user = encryptedUser
    ? JSON.parse(
      CryptoJS.AES.decrypt(encryptedUser, encryptionKey).toString(
        CryptoJS.enc.Utf8
      )
    )
    : null;
  const searchRef = useRef(null);
  const [suggestions, setSuggestions] = useState([]);
  const [showClubModal, setShowClubModal] = useState(false);

  const handleJoinClub = () => {
    setShowClubModal(true);
  };

  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const handlePayment = () => {
    setShowPaymentModal(true);
    setShowClubModal(false);
  };

  const handleCompletePayment = async () => {
    try {
      const clubRegisteredDate = new Date().toISOString();
      const response = await axios.post(
        `https://parnetslink.xyz/api/user/changeClubStatus/${user._id}`,
        { clubRegisteredDate },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        let updatedUser = { ...user, club: true, clubRegisteredDate };
        const encryptedUser = CryptoJS.AES.encrypt(
          JSON.stringify(updatedUser),
          encryptionKey
        ).toString();
        Cookies.set("user", encryptedUser, {
          expires: 7,
          secure: true,
          sameSite: "Strict",
        });
        localStorage.removeItem("clubModalSkipped");
        toast.success("Successfully registered as club member!");
        setShowPaymentModal(false);
        window.location.reload();
      }
    } catch (error) {
      toast.error("Unable to complete registration. Please try again.");
    }
  };

  const getproducts = async () => {
    try {
      let res = await axios.get(
        "https://parnetslink.xyz/api/admin/Getproductlist",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) {
        setData(
          res.data.ProductList.filter((item) => item.status === "Active")
        );
      }
    } catch (error) {
      console.error("Error fetching product list", error);
    }
  };

  useEffect(() => {
    getproducts();
  }, []);

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };
  const [texts, setTexts] = useState([]);
  const fetchTexts = async () => {
    try {
      const response = await axios.get(
        `https://parnetslink.xyz/api/admin/getText`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTexts(response.data?.Text || []);
    } catch (error) {
      toast.error("Failed to fetch texts");
    }
  };

  const [profileData, setProfileData] = useState({});
  const getprofile = async () => {
    try {
      let res = await axios.get(
        "https://parnetslink.xyz/api/admin/getbussinessprofile"
      );
      if (res.status == 200) {
        setProfileData(res.data.profile);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [categorydata, setCategorydata] = useState([]);
  const getcategory = async () => {
    let res = await axios.get("https://parnetslink.xyz/api/admin/getCategory", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.status === 200) {
      setCategorydata(
        res.data?.category.filter((item) => item.status === "Active")
      );
    }
  };
  const [deliveryCharges, setDeliveryCharges] = useState({});
  const fetchDeliveryCharges = async () => {
    try {
      const res = await axios.get(
        "https://parnetslink.xyz/api/admin/getactivedelivery",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) {
        setDeliveryCharges(res.data?.data);
      }
    } catch (error) {
      console.error("Error fetching delivery charges:", error);
      // message.error("Failed to fetch delivery charges");
    }
  };
  useEffect(() => {
    fetchDeliveryCharges();
    getprofile();
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const categoryid = categorydata?.find(
    (ele) => ele?.categoryName == "Fruits & Vegetables"
  );
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchValue(value);

    if (value.length > 0) {
      const filteredSuggestions = Data.filter((item) =>
        item.productname.toLowerCase().includes(value.toLowerCase())
      ).slice(0, 5);
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    console.log("Suggestion clicked:", suggestion);
    setSearchValue(suggestion.productname);
    setSuggestions([]);
    setShowDropdown(false);
    if (categoryid?._id == suggestion?.category) {
      navigate(`/shop-detail1/${suggestion?._id}`);
    } else {
      navigate(`/shop-detail/${suggestion?._id}`);
    }
    // navigate(`/shop-detail/${suggestion._id}`);
    setSearchValue("");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // console.log(searchValue, "searchValue");
  const openModal = () => {
    if (!token) return toast.warn("Please login to proceed!");
    setIsModalOpen(true);
  };

  const closeModal1 = () => setIsModalOpen1(false);
  const closeModal = () => setIsModalOpen(false);

  const scrollToTop = () => {
    navigate("/");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [options, setOptions] = useState([{ value: '', label: 'Shop By Category', disabled: true }]);

  const [isOpen, setIsOpen] = useState(false); const [selectedOption, setSelectedOption] = useState(options[0]); const handleDropdownToggle1 = () => { setIsOpen(!isOpen); }; 
  const handleOptionSelect = (option) => { setSelectedOption(option); setIsOpen(false); scrollToTop1(option.value)};


  // const fetchCategories = async () => {
  //   try {
  //     const res = await axios.get(
  //       "https://parnetslink.xyz/api/admin/getCategory",
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     if (res.status === 200) {
  //       setCategoryData(
  //         res.data?.category.filter((item) => item.status === "Active")
  //       );

  //       const categoryMapping = {};
  //       res.data?.category
  //         .filter((item) => item.status === "Active")
  //         .forEach((cat) => {
  //           categoryMapping[cat._id] = cat.categoryName;
  //         });
  //       setCategoryMap(categoryMapping);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching categories:", error);
  //   }
  // };


  const fetchCategories = async () => { try { const res = await axios.get("https://parnetslink.xyz/api/admin/getCategory", { headers: { Authorization: `Bearer ${token}`, }, }); if (res.status === 200) { const activeCategories = res.data?.category.filter((item) => item.status === "Active"); setCategoryData(activeCategories); const categoryMapping = {}; activeCategories.forEach((cat) => { categoryMapping[cat._id] = cat.categoryName; }); setCategoryMap(categoryMapping); const formattedOptions = activeCategories.map((category) => ({ value: category._id, label: category.categoryName, })); setOptions([{ value: '', label: 'Shop By Category', disabled: true }, ...formattedOptions]); } } catch (error) { console.error("Error fetching categories:", error); } };

  const navigation = useNavigate();
  const scrollToTop1 = (categoryName) => {
    navigation("/shop", { state: { categoryId: categoryName } });
  };


  useEffect(() => { fetchCategories(); }, []);

  return (

    <div
      className="sticky top-0 z-50 shadow-md"
      style={{ backgroundColor: "#F1F7E5", paddingBottom: "10px" }}
    >
      <div className="hidden lg:block">
        <div className="web-top-para_edited gradient1 text-white  p-2 flex lg:justify-between lg:h-[70px] flex-col lg:flex-row ">
          <div className="lg:w-[60%] flex justify-center items-center mt-3 w-full">
            <marquee className="flex flex-col " direction="left">
              {/* {texts.slice(0, 1).map((e) => ( */}
              {/* className="config-para text-center text-xs sm:text-sm md:text-base mt-2" */}
              {/* be343b */}
              <p className="">{showText}</p>
              {/* // ))} */}
            </marquee>
          </div>

          <div className="lg:w-[40%] flex lg:justify-around lg:items-center w-full flex-col lg:flex-row">
            <div className="flex items-center">
              <Lottie animationData={deliverytruck} className="size-20" />
              <div>
                <h6 className="text-sm m-0 p-0 ">Free Shipping</h6>
                <h2 className="text-lg font-semibold ">
                  On Order above ₹{deliveryCharges?.minAmount}/-
                </h2>
              </div>
            </div>

            <div className="flex items-center ml-6 lg:ml-0">
              <Lottie animationData={phone} className="size-10" />
              <div className="flex flex-col ">
                <h6 className="text-sm m-0 p-0">Call Anytime</h6>
                <h2 className="text-lg font-semibold">
                  +91-{profileData?.phoneNumber}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden md:flex md:container md:mx-auto justify-around sticky z-50 top-0 left-0 item-center">
        <div className="">
          <div onClick={() => scrollToTop()}>
            <img
              alt="logo"
              className="desktop-logo hide-mobile cursor-pointer"
              src={`https://parnetslink.xyz/profile/${profileData.businesslogo}`}
              loading="lazy"
              style={{
                width: "320px",
                height: "70px",
                marginLeft: "-10px",
                marginTop: "20px",
              }}
            />
          </div>
        </div>

        {/* class="w-[80%] border border-gray-300 rounded-md p-2 bg-transparent text-gray-700 px-2" */}

        <div className="px-3 duration-200 ease-linear">
          <div className="w-52 mx-auto mt-8 text-white bg-[#5c870c] rounded-md  flex px-2 flex-col items-center space-y-2 mb-6 relative">
            <div className="flex items-center space-x-2 w-full"> <RxDashboard className="text-white text-2xl" /> <button className="bg-[#5c870c] text-white w-full rounded-md py-2 focus:outline-none text-left truncate" onClick={handleDropdownToggle1} > {selectedOption.label} </button>
            </div>
            {isOpen && (<ul className="absolute top-full left-0 w-full bg-[#5c870c] rounded-md shadow-lg z-10 mt-1 truncate"> {options.map((option, index) => (
              <li key={index} className={`px-4 py-2 cursor-pointer hover:bg-[#4d7208] ${option.disabled ? 'cursor-not-allowed' : ''}`} onClick={() => !option.disabled && handleOptionSelect(option)} > {option.label} </li>))} 
              </ul>)}
          </div>
        </div>

        <div
          className="relative box-border w-[28%] mt-2 rounded-md"
          style={{ alignSelf: "center" }}
          ref={searchRef}
        >
          <input
            className="border border-orange-400 w-full rounded-md pl-4 py-2 bg-transparent"
            type="text"
            placeholder="Search your products..."
            value={searchValue}
            onChange={handleSearchChange}
            style={{ paddingRight: "50px" }}
          />
          <button
            className="absolute right-2 top-1/2 transform -translate-y-1/2  text-[#5c870c] rounded-full w-10 h-10 flex items-center justify-center"
          // onClick={() => {
          //   if (searchValue) navigate(`/shop?search=${searchValue}`);
          // }}
          // bg-[#5C870C]
          >
            <SearchOutlined
              onClick={() => message.error("Please enter your products name!")}
            />
          </button>
          {searchValue.length > 0 && (
            <div className="absolute w-full mt-1 border border-orange-400 rounded-lg bg-white shadow-lg z-index-fix">
              <ul className="list-none p-2">
                {suggestions.length > 0 ? (
                  suggestions
                    ?.sort((a, b) => a.productname - b.productname)
                    ?.map((suggestion) => (
                      <li
                        className="p-2 hover:bg-gray-200 cursor-pointer d-flex gap-2 align-items-center"
                        key={suggestion._id}
                        onClick={() => handleSuggestionClick(suggestion)}
                      >
                        <img
                          className=""
                          src={`https://parnetslink.xyz/product/${suggestion?.Photos[0].file}`}
                          alt={suggestion.productname}
                          loading="lazy"
                          style={{
                            width: "50px",
                            height: "50px",
                            objectFit: "cover",
                            borderRadius: "10px",
                          }}
                        />{" "}
                        <span style={{ textTransform: "capitalize" }}>
                          {suggestion.productname}
                        </span>
                      </li>
                    ))
                ) : (
                  <li className="p-2 hover:bg-gray-200 cursor-pointer">
                    Product not found
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>

        <ul className="flex justify-center items-center">
          <div className="flex">
            <Link to="/cart" className="flex flex-col items-center mx-2">
              <span
                className="fa fa-shopping-bag main-text p-3 relative icon-hov"
                style={{ fontSize: "1.5em" }}
              >
                <span className="absolute top-2 right-0 w-5 h-5 text-xs search flex justify-center items-center bord text-[#5c870c]">
                  {total}
                </span>
              </span>
              <span className="text-xs" style={{ marginTop: "-10px" }}>
                Cart
              </span>
            </Link>
            <Link to="/shop" className="flex flex-col items-center mx-2">
              <span
                className="fa fa-calendar main-text p-3 icon-hov"
                style={{ fontSize: "1.5em" }}
              ></span>
              <span
                className="text-xs"
                style={{ marginTop: "-10px", textAlign: "center" }}
              >
                My Shop
              </span>
            </Link>
            {user && (
              <Link to="/account" className="flex flex-col items-center mx-2">
                <span
                  className="fas fa-user main-text p-3 icon-hov"
                  style={{ fontSize: "1.5em" }}
                ></span>
                <span className="text-xs" style={{ marginTop: "-10px" }}>
                  Account
                </span>
              </Link>
            )}

            <Link to="/wallet" className="flex flex-col items-center mx-2">
              <span
                className="fas fa-wallet main-text p-3 icon-hov"
                style={{ fontSize: "1.5em" }}
              ></span>
              <span className="text-xs" style={{ marginTop: "-10px" }}>
                Wallet
              </span>
            </Link>

            {!user && (
              <Link to="/login" className="flex flex-col items-center mx-2">
                <span
                  className="fas fa-sign-in main-text p-3 icon-hov"
                  style={{ fontSize: "1.5em" }}
                ></span>
                <span className="text-xs" style={{ marginTop: "-10px" }}>
                  Login
                </span>
              </Link>
            )}
            {!user?.club && user && (
              <button
                onClick={handleJoinClub}
                className="flex flex-col items-center mx-2"
              >
                <span
                  className="fas fa-crown main-text p-3 icon-hov"
                  style={{ fontSize: "1.5em", color: "#be343b" }}
                ></span>
                <span
                  className="text-xs"
                  style={{ marginTop: "-10px", color: "#be343b" }}
                >
                  Join Club
                </span>
              </button>
            )}
            <button
              onClick={openModal}
              className="flex flex-col items-center mx-2"
            >
              <span
                className="fas fa-star main-text p-3 icon-hov"
                style={{ fontSize: "1.5em" }}
              ></span>
              <span
                className="text-xs"
                style={{ color: "#5C870C", marginTop: "-10px" }}
              >
                Feedback
              </span>
            </button>
          </div>
        </ul>
      </div>
      <div className="md:hidden">
        <div className="flex flex-col p-2">
          <div className="flex justify-between items-center mb-2">
            <Link to="/">
              <img
                alt="logo"
                className="w-32 h-auto"
                src="../logo2.png"
                loading="lazy"
              />
            </Link>
            <Link to="/cart" className="relative">
              <span className="fa fa-shopping-bag text-xl">
                <span className="absolute -top-1 -right-1 w-4 h-4 text-[10px] bg-[#5c870c] text-white rounded-full flex items-center justify-center">
                  {total}
                </span>
              </span>
            </Link>
          </div>
          <div className="mb-2 relative" ref={searchRef}>
            <input
              className="border border-orange-400 w-full rounded-full pl-3 py-1.5 pr-10 text-sm"
              type="text"
              placeholder="Search"
              value={searchValue}
              onChange={handleSearchChange}
            />
            <button
              className="absolute right-1.5 top-1/2 transform -translate-y-1/2 h-6 w-6 bg-[#5C870C] text-white rounded-full flex items-center justify-center"
            // onClick={() => !searchValue ? scrollToTop1() : null}
            >
              <SearchOutlined className="text-xs" />
            </button>

            {searchValue.length > 0 && (
              <div className="absolute w-full mt-1 border border-orange-400 rounded-lg bg-white shadow-lg z-index-fix">
                <ul className="list-none p-2">
                  {suggestions.length > 0 ? (
                    suggestions.map((suggestion) => (
                      <li
                        className="p-2 hover:bg-gray-200 cursor-pointer"
                        key={suggestion._id}
                        onClick={() => handleSuggestionClick(suggestion)}
                      >
                        {suggestion.productname}
                      </li>
                    ))
                  ) : (
                    <li className="p-2 hover:bg-gray-200 cursor-pointer">
                      Product not found
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div>
          <div className="flex justify-between px-2">
            <Link to="/shop" className="flex flex-col items-center">
              <span className="fa fa-calendar text-lg"></span>
              <span className="text-[10px]">My Shop</span>
            </Link>
            {user && (
              <Link to="/account" className="flex flex-col items-center">
                <span className="fas fa-user text-lg"></span>
                <span className="text-[10px]">Account</span>
              </Link>
            )}
            <Link to="/wallet" className="flex flex-col items-center">
              <span className="fas fa-wallet text-lg"></span>
              <span className="text-[10px]">Wallet</span>
            </Link>
            {!user && (
              <Link to="/login" className="flex flex-col items-center">
                <span className="fas fa-sign-in text-lg"></span>
                <span className="text-[10px]">Login</span>
              </Link>
            )}
            {!user?.club && user && (
              <button
                onClick={handleJoinClub}
                className="flex flex-col items-center mx-2"
              >
                <span
                  className="fas fa-crown text-lg"
                  style={{ fontSize: "1.5em", color: "#be343b" }}
                ></span>
                <span className="text-[10px]">Join Club</span>
              </button>
            )}
            <button onClick={openModal} className="flex flex-col items-center">
              <span className="fas fa-star text-lg text-[#5C870C]"></span>
              <span className="text-[10px] text-[#5C870C]">Feedback</span>
            </button>
          </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <FeedbackForm />
      </Modal>

      <Modal isOpen={isModalOpen1} onClose={closeModal1}>
        <DeliveryLocationModal onClose={closeModal1} />
      </Modal>
      <ClubModal
        isOpen={showClubModal}
        onClose={() => setShowClubModal(false)}
        onJoinClick={handlePayment}
      />
      <PaymentModal
        isOpen={showPaymentModal}
        onClose={() => setShowPaymentModal(false)}
        onConfirmPayment={handleCompletePayment}
      />
    </div>
  );
};

export default Header;
