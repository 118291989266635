import React from "react";
import { Table } from "antd";
import moment from "moment";

const StockHistoryTable = ({ stockHistory }) => {

  console.log("stockhistory",stockHistory);
  
  const columns = [
    {
      title: "Stock Name",
      dataIndex: "stockName",
      key: "stockName",
      // render: (text) => <strong>{text?.stockName}</strong>,
    },
    {
      title: "Previous Stock",
      dataIndex: "previousStock",
      key: "previousStock",
    },
    {
      title: "Added Stock",
      dataIndex: "addedStock",
      key: "addedStock",
    },
    {
      title: "New Stock",
      dataIndex: "newStock",
      key: "newStock",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render:(_,record)=>{
        return <span>{record?.date &&moment(record.date).format('lll')}</span>
      }
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={stockHistory}
      rowKey={(record, index) => index} // Use index as unique key
      pagination={false} // Disable pagination for compact display
      size="small" // Smaller table size for better fit
    />
  );
};

export default StockHistoryTable;
