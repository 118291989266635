import "./App.css";
import React, { useEffect, useRef, useState } from "react";
import { initializeApp } from "firebase/app";
import { getMessaging, onMessage } from "firebase/messaging";
import Pages from "./components/pages/Pages";
import "antd/dist/reset.css";

import { getToken } from "firebase/messaging";
import { messaging } from "./firebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import axios from "axios";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";

function App() {
  // Retrieve encrypted data from cookies
  const encryptedToken = Cookies.get("token");
  const encryptedUser = Cookies.get("user");
  const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;
// console.log("encryptionKey check==>",encryptionKey,encryptedToken);

  // if (!encryptedToken || !encryptedUser || !encryptionKey) {
  //   console.error("Missing encrypted data or encryption key");
  // }

  // Decrypt the data
  const token = encryptedToken
    ? CryptoJS.AES.decrypt(encryptedToken, encryptionKey).toString(
        CryptoJS.enc.Utf8
      )
    : null;


    console.log("token two",token);
    
  const user = encryptedUser
    ? JSON.parse(
        CryptoJS.AES.decrypt(encryptedUser, encryptionKey).toString(
          CryptoJS.enc.Utf8
        )
      )
    : null;


  useEffect(() => {
    console.log("Setting up message listener");
    const unsubscribe = onMessage(messaging, (payload) => {
      console.log("Message received:", payload);
      console.log("Attempting to show notification");
      if (Notification.permission === "granted") {
        const notificationTitle = payload.notification.title;
        const notificationOptions = {
          body: payload.notification.body,
          icon: "../img/success.png",
        };
        console.log("Attempting to show notification111");
        new Notification(notificationTitle, notificationOptions);
      }
      try {
        toast.info(
          `${payload.notification.title}: ${payload.notification.body}`
        );
        console.log("Notification displayed successfully");
      } catch (error) {
        console.error("Error displaying notification:", error);
      }
    });

    return () => {
      console.log("Cleaning up message listener");
      unsubscribe();
    };
  }, []);
  const items = useSelector((state) => state.cart.items);
  const timeoutRef = useRef(null);
  const lastNotificationRef = useRef(null);
  const timeoutDuration = 10 * 60 * 1000;
  const [notifiedState, setNotifiedState] = useState({
    cart: null,
    timestamp: null,
  });

  const isCartEqual = (cart1, cart2) => {
    if (!cart1 || !cart2) return false;
    const normalizedCart1 = cart1.map((item) => ({
      ...item,
      timestamp: undefined,
    }));

    const normalizedCart2 = cart2.map((item) => ({
      ...item,
      timestamp: undefined,
    }));

    return JSON.stringify(normalizedCart1) === JSON.stringify(normalizedCart2);
  };

  const canNotifyAgain = () => {
    if (!notifiedState.timestamp) return true;
    const timeSinceLastNotification =
      Date.now() - new Date(notifiedState.timestamp).getTime();
    const minimumInterval = 60 * 60 * 1000;

    return timeSinceLastNotification >= minimumInterval;
  };

  const notifyAdmin = async (cartData) => {
    if (isCartEqual(cartData, notifiedState.cart) && !canNotifyAgain()) {
      console.log("Skipping notification - similar cart was recently notified");
      return;
    }
      if(!token) return
    try {
      const response = await axios.post(
        "https://parnetslink.xyz/api/admin/abandoned-cart",
        {
          cart: cartData,
          user: user,
          timestamp: new Date(),
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      console.log("Admin notified of abandoned cart:", response.data);
      setNotifiedState({
        cart: cartData,
        timestamp: new Date(),
      });
      lastNotificationRef.current = Date.now();
    } catch (error) {
      console.error("Error notifying admin:", error);
    }
  };

  useEffect(() => {
    if (items.length > 0 && user) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      if (!isCartEqual(items, notifiedState.cart) || canNotifyAgain()) {
        timeoutRef.current = setTimeout(() => {
          console.log("Cart is abandoned, notifying admin...");
          notifyAdmin(items);
        }, timeoutDuration);
      }
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [items, user, notifiedState]);

  const API_BASE_URL = "https://parnetslink.xyz/api/admin";
  const fetchTexts = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/getActiveText`, {});
      console.log("response.data?.data", response.data?.data);
      // Assuming `response.data?.data` is your data source// Assuming `response.data?.data` is your data source
let am = response.data?.data;


// Function to check if current day and time are within range
const data = {
  category: "Fruits & Vegetables",
  deliveryCharge: 30,
  endTime: "12:00",
  fromDay: "Wednesday",
  fromTime: "12:00",
  isActive: true,
  text: "Fruits and vegetables orders are available from Saturday 12 PM to Monday 12 PM. | Delivery will be completed by Tuesday, 2 PM",
  toDay: "Tuesday",
};

function isShowOpen(data) {
  if (!data) {
    return false
  }
  const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const currentDateTime = new Date();

  // Get current day, time
  const currentDay = currentDateTime.getDay(); // 0 = Sunday, ..., 6 = Saturday
  const currentTime = currentDateTime.toTimeString().slice(0, 5); // HH:mm format

  // Get indices for fromDay and toDay
  const fromDayIndex = daysOfWeek.indexOf(data.fromDay);
  const toDayIndex = daysOfWeek.indexOf(data.toDay);

  // Check if the current day is in range
  let isDayInRange;
  if (fromDayIndex <= toDayIndex) {
    // Normal order (e.g., Wednesday to Tuesday)
    isDayInRange = currentDay >= fromDayIndex && currentDay <= toDayIndex;
  } else {
    // Wrap-around order (e.g., Wednesday to Tuesday of the next week)
    isDayInRange = currentDay >= fromDayIndex || currentDay <= toDayIndex;
  }

  // Special cases: Check `fromTime` and `endTime`
  const [fromHours, fromMinutes] = data.fromTime.split(":").map(Number);
  const [endHours, endMinutes] = data.endTime.split(":").map(Number);

  const currentHours = parseInt(currentTime.split(":")[0], 10);
  const currentMinutes = parseInt(currentTime.split(":")[1], 10);

  const currentTotalMinutes = currentHours * 60 + currentMinutes;
  const fromTotalMinutes = fromHours * 60 + fromMinutes;
  const endTotalMinutes = endHours * 60 + endMinutes;

  // If it's `fromDay`, ensure time is after `fromTime`
  if (currentDay === fromDayIndex && currentTotalMinutes < fromTotalMinutes) {
    return false;
  }

  // If it's `toDay`, ensure time is before `endTime`
  if (currentDay === toDayIndex && currentTotalMinutes >= endTotalMinutes) {
    return false;
  }

  // Show is open if the day and time checks pass
  return isDayInRange;
}




// Check the condition and store in cookies
if (isShowOpen(am)) {

  const AllText = encryptionKey
    ? CryptoJS.AES.encrypt(JSON.stringify(am), encryptionKey).toString()
    : "";
 
  Cookies.set("AllText", AllText, { expires: 7, secure: true, sameSite: "Strict" });
} else {
  Cookies.remove("AllText");

  Cookies.set("AllTextHead", am.text ? am.text:"" , { expires: 7, secure: true, sameSite: "Strict" });
}
if(am){
  Cookies.set("AllTextHead", am?.text  , { expires: 7, secure: true, sameSite: "Strict" });
}else{
  Cookies.remove("AllTextHead");
}

    } catch (error) {
      console.log(error);
      Cookies.remove("AllTextHead");
      Cookies.remove("AllText");
      // message.error("Failed to fetch texts");
    }
  };

  useEffect(() => {
    fetchTexts();
  }, []);

  return (
    <div className="App">
      <ToastContainer />
      <Pages />
    </div>
  );
}

export default App;
