import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  items: [],
  totalItems: 0,
  uniqueItems: 0,
  subtotal: 0,
  total: 0,
};

const calculateTotals = (items) => {
  const subtotal = items.reduce((sum, item) => {
    const selectedWeight = item.selectedWeight || item.weight;
    const price = selectedWeight?.price || 0;
    const discount = selectedWeight?.discount || item?.discount || 0;
    const discountedPrice = price - (price * (discount / 100));
    
    return sum + discountedPrice * item.quantity;
  }, 0);
  
  const total = subtotal; 
  return { subtotal, total };
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCarts: (state, action) => {
      const existingItem = state.items.find(item => item._id === action.payload._id);
      if (existingItem) {
        existingItem.quantity += 1;
      } else {
        state.items.push({ ...action.payload, quantity: 1 });
        state.uniqueItems += 1;  
      }
      state.totalItems = state.items.reduce((total, item) => total + item.quantity, 0);
      const totals = calculateTotals(state.items);
      state.subtotal = totals.subtotal;
      state.total = totals.total;
    },
    addToCart: (state, action) => {
      const { _id, selectedWeight, quantity = 1 } = action.payload;
      const existingItemIndex = state.items.findIndex(item => 
        item._id === _id && 
        item.selectedWeight?.value === selectedWeight?.value &&
        item.selectedWeight?.units === selectedWeight?.units
      );
      
      if (existingItemIndex !== -1) {
        state.items[existingItemIndex].quantity += quantity;
      } else {
        state.items.push({ ...action.payload, quantity });
        state.uniqueItems += 1;
      }
      
      state.totalItems = state.items.reduce((total, item) => total + item.quantity, 0);
      const totals = calculateTotals(state.items);
      state.subtotal = totals.subtotal;
      state.total = totals.total;
    },
    
    updateCartItemQuantity: (state, action) => {
      const { itemId, quantity, weight } = action.payload;
      const itemIndex = state.items.findIndex(item => 
        item._id === itemId && 
        item.selectedWeight?.value === weight?.value &&
        item.selectedWeight?.units === weight?.units
      );
      
      if (itemIndex !== -1) {
        if (quantity > 0) {
          state.items[itemIndex].quantity = quantity;
        } else {
          state.items.splice(itemIndex, 1);
          state.uniqueItems -= 1;
        }
        
        state.totalItems = state.items.reduce((total, item) => total + item.quantity, 0);
        const totals = calculateTotals(state.items);
        state.subtotal = totals.subtotal;
        state.total = totals.total;
      }
    },
    
    removeFromCart: (state, action) => {
      const index = state.items.findIndex(item => 
        item._id === action.payload._id && 
        item.selectedWeight?.value === action.payload.selectedWeight?.value &&
        item.selectedWeight?.units === action.payload.selectedWeight?.units
      );
      
      if (index !== -1) {
        if (state.items[index].quantity > 1) {
          state.items[index].quantity -= 1;
        } else {
          state.items.splice(index, 1);
          state.uniqueItems -= 1;
        }
      }
      
      state.totalItems = state.items.reduce((total, item) => total + item.quantity, 0);
      const totals = calculateTotals(state.items);
      state.subtotal = totals.subtotal;
      state.total = totals.total;
    },
    
    clearItem: (state, action) => {
      const index = state.items.findIndex(item => 
        item._id === action.payload._id && 
        item.selectedWeight?.value === action.payload.selectedWeight?.value &&
        item.selectedWeight?.units === action.payload.selectedWeight?.units
      );
      
      if (index !== -1) {
        state.items.splice(index, 1);
        state.uniqueItems -= 1;
      }
      
      state.totalItems = state.items.reduce((total, item) => total + item.quantity, 0);
      const totals = calculateTotals(state.items);
      state.subtotal = totals.subtotal;
      state.total = totals.total;
    },
    
    clearCart: (state) => {
      console.log('Clearing Cart:', state.items); 
      state.items = [];
      state.totalItems = 0;
      state.uniqueItems = 0;
      state.subtotal = 0;
      state.total = 0;
      console.log('Cart after clearing:', state.items); 
    },
    
  },
});

export const { 
  addToCart, 
  addToCarts, 
  removeFromCart, 
  clearItem, 
  clearCart, 
  updateCartItemQuantity 
} = cartSlice.actions;

export default cartSlice.reducer;