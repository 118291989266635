import React, { lazy, Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "../common/header/Header";
import Footer from "../common/footer/Footer";
import Loader from "../Loader";
import axios from "axios";
import Stock from "../admin/pages/Stock";

const Home = lazy(() => import("../Home"));
const Shop = lazy(() => import("../Shop"));
const ShopDetail = lazy(() => import("../ShopDetail"));
const ShopDetail1 = lazy(() => import("../ShopDetail1"));
const Scheduled = lazy(() => import("../Scheduled"));
const TestimonialMain = lazy(() => import("../testimonial/TestimonialMain"));
const Cart = lazy(() => import("../carts"));
const Checkout = lazy(() => import("../Checkout"));
const Wallet = lazy(() => import("../wallet"));
const LoginForm = lazy(() => import("../login"));
const LoginRegister = lazy(() => import("../register"));
const Account = lazy(() => import("../Account"));
const Wishlist1 = lazy(() => import("../wishlist"));
const TermsOfService = lazy(() => import("../Terms"));
const PrivacyPolicy = lazy(() => import("../privacy"));
const RefundPolicy = lazy(() => import("../refund"));
const ShippingPolicy = lazy(() => import("../shipping"));
const SubscribeCart = lazy(() => import("../subscribes"));
const ClubLoginForm = lazy(() => import("../clublogin"));
const ClubLoginRegister = lazy(() => import("../clubregister"));
const Contact = lazy(() => import("../Contact"));
const Error = lazy(() => import("../Error"));
const InvoicePage = lazy(() => import("../Invoice"));

// Admin Pages
const Login = lazy(() => import("../admin/pages/Login"));
const Resetpassword = lazy(() => import("../admin/pages/Resetpassword"));
const Forgotpassword = lazy(() => import("../admin/pages/Forgotpassword"));
const MainLayout = lazy(() => import("../admin/components/MainLayout"));
const Dashboard = lazy(() => import("../admin/pages/Dashboard"));
const AddBanner = lazy(() => import("../admin/pages/addbanner"));
const AddCategory = lazy(() => import("../admin/pages/addcategory"));
const AddSubCategory = lazy(() => import("../admin/pages/addsubcategory"));
const AddclubBanner = lazy(() => import("../admin/pages/joinclubbanner"));
const Orders1 = lazy(() => import("../admin/pages/ScheduledOrders"));
const Leaves = lazy(() => import("../admin/pages/Driverleaves"));
const Checkins = lazy(() => import("../admin/pages/DriverCheckins"));
const Drivers = lazy(() => import("../admin/pages/Driver"));
const AddText = lazy(() => import("../admin/pages/AddText"));
const DeliveryChargesList = lazy(() => import("../admin/pages/DeliveryCharges"));
const Enquiries=lazy(()=>import('../admin/pages/Enquiries'));

const ViewEnq=lazy(()=>import('../admin/pages/ViewEnq'));
const Bloglist=lazy(()=>import('../admin/pages/Bloglist'));
const Addblog=lazy(()=>import('../admin/pages/Addblog'));
const Couponlist=lazy(()=>import('../admin/pages/Couponlist'));
const AbandonedCartList=lazy(()=>import('../admin/pages/abandoned'));
const AddCoupon=lazy(()=>import('../admin/pages/AddCoupon'));

const Blogcatlist=lazy(()=>import('../admin/pages/Blogcatlist'));
const Addblogcat=lazy(()=>import('../admin/pages/Addblogcat'));
const Orders=lazy(()=>import('../admin/pages/Orders'));
const Subscriptions=lazy(()=>import('../admin/pages/subscriptionorder'));
const AddTestimonial=lazy(()=>import('../admin/pages/addTestimonial'));
const ViewOrder=lazy(()=>import('../admin/pages/ViewOrder'));
const Customers=lazy(()=>import('../admin/pages/Customers'));
const ClubCustomers=lazy(()=>import('../admin/pages/ClubCustomers'));

const Colorlist=lazy(()=>import('../admin/pages/Colotlist'));
const Addcolor=lazy(()=>import('../admin/pages/Addcolor'));
const Categorylist=lazy(()=>import('../admin/pages/Categorylist'));
const Addcat=lazy(()=>import('../admin/pages/Addcat'));
const Brandlist=lazy(()=>import('../admin/pages/Brandlist'));
const Addbrand=lazy(()=>import('../admin/pages/Addbrand'));
const AddTitle=lazy(()=>import('../admin/pages/AddTitle'));
const AddTag=lazy(()=>import('../admin/pages/AddTag'));
const AddUnit=lazy(()=>import('../admin/pages/AddUnit'));
const Productlist=lazy(()=>import('../admin/pages/Productlist'));
const FruitsList=lazy(()=>import('../admin/pages/Fruitsegetableslist'));
const Addproduct=lazy(()=>import('../admin/pages/Addproduct'));
const AddDiscountBanner =lazy(()=>import('../admin/pages/adddisbanner'));
const AddFruits=lazy(()=>import('../admin/pages/AddvegetableFruits'));
const AdminProfile=lazy(()=>import('../admin/pages/AdminProfile'));
const AdminLogin=lazy(()=>import('../admin/pages/Login'))
const AdminStock=lazy(()=>import('../admin/pages/Stock'));
const AboutUs = lazy(() => import("../AboutUs"));

const AdminRoute = ({ children }) => <>{children}</>;

const ClientRoute = ({ children }) => (
  <>
    <Header />
    {children}
    <Footer />
  </>
);


const Pages = () => {


  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <Routes>
          {/* Client Routes */}
          <Route
            path="/"
            element={
              <ClientRoute>
                <Home />
              </ClientRoute>
            }
          />
          <Route
            path="/shop"
            element={
              <ClientRoute>
                <Shop />
              </ClientRoute>
            }
          />
          <Route
            path="/Scheduled"
            element={
              <ClientRoute>
                <Scheduled />
              </ClientRoute>
            }
          />
             <Route
            path="/aboutus"
            element={
              <ClientRoute>
                <AboutUs />
              </ClientRoute>
            }
          />
          <Route
            path="/shop-detail/:id"
            element={
              <ClientRoute>
                <ShopDetail />
              </ClientRoute>
            }
          />
          <Route
            path="/shop-detail1/:id"
            element={
              <ClientRoute>
                <ShopDetail1 />
              </ClientRoute>
            }
          />
          <Route
            path="/testimonial"
            element={
              <ClientRoute>
                <TestimonialMain />
              </ClientRoute>
            }
          />
          <Route
            path="/cart"
            element={
              <ClientRoute>
                <Cart />
              </ClientRoute>
            }
          />
          <Route
            path="/clublogin"
            element={
              <ClientRoute>
                <ClubLoginForm />
              </ClientRoute>
            }
          />
          <Route
            path="/login"
            element={
              <ClientRoute>
                <LoginForm />
              </ClientRoute>
            }
          />
          <Route
            path="/clubregister"
            element={
              <ClientRoute>
                <ClubLoginRegister />
              </ClientRoute>
            }
          />
          <Route
            path="/register"
            element={
              <ClientRoute>
                <LoginRegister />
              </ClientRoute>
            }
          />
          <Route
            path="/wallet"
            element={
              <ClientRoute>
                <Wallet />
              </ClientRoute>
            }
          />
          <Route
            path="/account"
            element={
              <ClientRoute>
                <Account />
              </ClientRoute>
            }
          />
          <Route
            path="/wishlist"
            element={
              <ClientRoute>
                <Wishlist1 />
              </ClientRoute>
            }
          />
          <Route
            path="/terms"
            element={
              <ClientRoute>
                <TermsOfService />
              </ClientRoute>
            }
          />
          <Route
            path="/privacypolicy"
            element={
              <ClientRoute>
                <PrivacyPolicy />
              </ClientRoute>
            }
          />
          <Route
            path="/shippingpolicy"
            element={
              <ClientRoute>
                <ShippingPolicy />
              </ClientRoute>
            }
          />
          <Route
            path="/refundpolicy"
            element={
              <ClientRoute>
                <RefundPolicy />
              </ClientRoute>
            }
          />
          <Route
            path="/contact"
            element={
              <ClientRoute>
                <Contact />
              </ClientRoute>
            }
          />
           <Route
          path="/checkout"
          element={
            <ClientRoute>
              <Checkout />
            </ClientRoute>
          }
        />
         
          <Route
            path="/subscribe/:id"
            element={
              <ClientRoute>
                <SubscribeCart />
              </ClientRoute>
            }
          />
          <Route
            path="/orderinvoice"
            element={
              <ClientRoute>
                <InvoicePage />
              </ClientRoute>
            }
          />

          {/* Admin Routes */}
          <Route
            path="/admin"
            element={
              <AdminRoute>
                <Login />
              </AdminRoute>
            }
          />
           <Route
          path="/reset-password"
          element={
            <AdminRoute>
              <Resetpassword />
            </AdminRoute>
          }
        />
         <Route
          path="/forgot-password"
          element={
            <AdminRoute>
              <Forgotpassword />
            </AdminRoute>
          }
        />
        {/* <Route 
        path="/admin/signin"
          element={<>
          <AdminLogin/>
          </>}
        /> */}
          <Route
            path="/admindashboard"
            element={
              <AdminRoute>
                <MainLayout />
              </AdminRoute>
            }
          >
            <Route index element={<Dashboard />} />
            <Route path="banner" element={<AddBanner />} />
            <Route path="clubbanner" element={<AddclubBanner />} />
            <Route path="disbanner" element={<AddDiscountBanner />} />
            <Route path="category" element={<AddCategory />} />
            <Route path="subcategory" element={<AddSubCategory />} />
            <Route path="orders1" element={<Orders1 />} />
            <Route path="leaves" element={<Leaves />} />
            <Route path="Checkins" element={<Checkins />} />
            <Route path="Drivers" element={<Drivers />} />
            <Route path="text" element={<AddText />} />
            <Route path="DeliveryChargesList" element={<DeliveryChargesList />} />
            <Route path="stock" element={<Stock />} />

            <Route path="enquiries" element={<Enquiries />} />
          <Route path="enquiries/:id" element={<ViewEnq />} />
          <Route path="blog-list" element={<Bloglist />} />
          <Route path="blog" element={<Addblog />} />
          <Route path="blog/:id" element={<Addblog />} />
          <Route path="coupon-list" element={<Couponlist />} />
          <Route path="AbandonedCartList" element={<AbandonedCartList />} />

          <Route path="coupon" element={<AddCoupon />} />
          <Route path="coupon/:id" element={<AddCoupon />} />
          <Route path="blog-category-list" element={<Blogcatlist />} />
          <Route path="blog-category" element={<Addblogcat />} />
          <Route path="blog-category/:id" element={<Addblogcat />} />
          <Route path="orders" element={<Orders />} />
          <Route path="orders1" element={<Orders1 />} />
          <Route path="subscription" element={<Subscriptions />} />
          <Route path="testimonial" element={<AddTestimonial />} />
          <Route path="order/:id" element={<ViewOrder />} />
          <Route path="customers" element={<Customers />} />
          <Route path="clubcustomers" element={<ClubCustomers />} />

          <Route path="list-color" element={<Colorlist />} />
          <Route path="color" element={<Addcolor />} />
          <Route path="color/:id" element={<Addcolor />} />
          <Route path="list-category" element={<Categorylist />} />
          <Route path="categorys" element={<Addcat />} />
          <Route path="categorys/:id" element={<Addcat />} />
          <Route path="list-type" element={<Brandlist />} />
          <Route path="type" element={<Addbrand />} />
          <Route path="title" element={< AddTitle/>} />
          <Route path="tag" element={<AddTag />} />
          <Route path="text" element={<AddText />} />

          <Route path="unit" element={<AddUnit />} />
          <Route path="type/:id" element={<Addbrand />} />
          <Route path="list-product" element={<Productlist />} />
          <Route path="list-Fruits" element={<FruitsList />} />
          <Route path="product" element={<Addproduct />} />
          <Route path="DeliveryChargesList" element={<DeliveryChargesList />} />
          <Route path="fruits" element={<AddFruits />} />
          <Route path="business-profile" element={<AdminProfile />} />
        </Route>
        <Route path="/orderinvoice" element={<InvoicePage />} />
        <Route
            path="*"
            element={
              <ClientRoute>
                <Error />
              </ClientRoute>
            }
          />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default Pages;
