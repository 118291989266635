import React, { useState, useEffect } from "react";
import {
  Table,
  Input,
  Button,
  Modal,
  Form,
  message,
  Space,
  Card,
  Typography,
  InputNumber,
  List,
} from "antd";
import {
  Search,
  Upload as UploadIcon,
  FileSpreadsheet,
  Image as ImageIcon,
} from "lucide-react";
import { Modal as BootstrapModal, TabPane } from "react-bootstrap";
import axios from "axios";
import { styled } from "@mui/material/styles";
import * as XLSX from "xlsx";
import parse from "html-react-parser";
import { Select, Paper } from "@mui/material";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";

import {
  FaArrowAltCircleDown,
  FaArrowAltCircleUp,
  FaArrowCircleUp,
} from "react-icons/fa";
import StockHistoryTable from "./StockHistoryTable";
import moment from "moment";
const { Title, Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;
const StyledDropzone = styled(Paper)(({ theme }) => ({
  border: `2px dashed ${theme.palette.divider}`,
  padding: theme.spacing(4),
  textAlign: "center",
  cursor: "pointer",
  marginBottom: theme.spacing(2),
  "&:hover": {
    borderColor: theme.palette.primary.main,
  },
}));

const Stock = () => {
  const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;
  const encryptedToken = Cookies.get("token");
  const token = encryptedToken
    ? CryptoJS.AES.decrypt(encryptedToken, encryptionKey).toString(
        CryptoJS.enc.Utf8
      )
    : null;

  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");

  const [categoryMap, setCategoryMap] = useState({});
  const [categoryData, setcategorydata] = useState([]);
  const [subCategoryData, setsubcategorydata] = useState([]);
  const [subCategoryMap, setSubCategoryMap] = useState({});

  const [uploadedImages, setUploadedImages] = useState([]);
  const getcategory = async () => {
    try {
      const res = await axios.get(
        "https://parnetslink.xyz/api/admin/getCategory",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) {
        setcategorydata(res.data?.category);
        const categoryMapping = {};
        res.data?.category.forEach((cat) => {
          categoryMapping[cat._id] = cat.categoryName;
        });
        setCategoryMap(categoryMapping);
      }
    } catch (error) {
      message.error("Failed to fetch categories");
    }
  };
  const getsubcategory = async () => {
    try {
      const res = await axios.get(
        "https://parnetslink.xyz/api/admin/getSubcategory",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) {
        setsubcategorydata(
          res.data?.Subcategory.filter((item) => item.status === "Active")
        );
        const subCategoryMapping = {};
        res.data?.Subcategory.filter(
          (item) => item.status === "Active"
        ).forEach((subcat) => {
          subCategoryMapping[subcat._id] = subcat.SubcategoryName;
        });
        setSubCategoryMap(subCategoryMapping);
      }
    } catch (error) {
      message.error("Failed to fetch subcategories");
    }
  };
  const [form] = Form.useForm();

  useEffect(() => {
    getcategory();
    getsubcategory();
  }, []);

  const fetchProducts = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        "https://parnetslink.xyz/api/admin/Getproductlist",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const filteredData = response.data.ProductList;
      setProducts(filteredData);
      setFilteredProducts(filteredData);
    } catch (error) {
      message.error("Failed to fetch products");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const handleSearch = (value) => {
    setSearchText(value);
    const filtered = products.filter((product) =>
      Object.values(product).some(
        (val) =>
          val && val.toString().toLowerCase().includes(value.toLowerCase())
      )
    );
    setFilteredProducts(filtered);
  };



  const handleStatusToggle = async (product) => {
    try {
      const endpoint =
        product.status === "Active"
          ? "changeActiveStatus"
          : "changeInactiveStatus";
      await axios.get(
        `https://parnetslink.xyz/api/admin/${endpoint}/${product._id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      message.success(
        `Product ${
          product.status === "Active" ? "blocked" : "unblocked"
        } successfully`
      );
      fetchProducts();
    } catch (error) {
      message.error("Failed to update product status");
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [AllStock, setAllStock] = useState([]);

  const handleOpenModal = (product) => {
    setSelectedProduct(product);
    setAllStock(product?.stock_history);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    form.resetFields();
    setIsModalOpen(false);
    setSelectedProduct({});
  };

  const handleStockUpdate = async (values) => {
    try {
      const { stockName, addedStock } = values;

      const response = await axios.put(
        "https://parnetslink.xyz/api/admin/addStockPoduct",
        {
          productId: selectedProduct._id,
          stockName,
          addedStock,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add Bearer token to Authorization header
          },
        }
      );

      message.success(response.data.message);
      handleCloseModal();
      fetchProducts();
    } catch (error) {
      console.error(error);
      message.error("Failed to update stock.");
    }
  };
  const exportToExcel = () => {
    const exportData = AllStock.map((ele,i)=>{
      return {
        "S.No":i+1,
        "StockID":ele?._id,
        "Stock_Name":ele.stockName,
        "Added_Stock":ele.addedStock,
        "Previous_Stock":ele?.previousStock,
        "New_Stock":ele?.newStock,
        "Date_Time":moment(ele.date).format('lll')
      }
    })
    //   ({
    //     stockName,
    //     previousStock,
    //     addedStock,
    //     newStock,
    //     date,
      
    //     ...rest
    //   }) => rest
    // );
    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Products");
    XLSX.writeFile(workbook,  `${selectedProduct?.productname}-export.xlsx`);
  };
  const columns = [
    {
      title: "Product Name",
      dataIndex: "productname",
      key: "productname",
      sorter: (a, b) => a.productname.localeCompare(b.productname),
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (category) => categoryMap[category] || "Unknown Category",
    },
    {
      title: "Subcategory",
      dataIndex: "subcategory",
      key: "subcategory",
      render: (subcategory) =>
        subCategoryMap[subcategory] || "Unknown Subcategory",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      sorter: (a, b) => a.price - b.price,
    },
    {
      title: "Stock",
      dataIndex: "stock",
      key: "stock",
      render: (_, record) => {
        return (
          <Space>
            <span
              onClick={() => handleOpenModal(record)}
              style={{
                color:
                  record?.ministock < record?.stock - record?.sellproduct
                    ? "green"
                    : "red",
                display: "flex",
                alignItems: "center",
                gap: "3px",
                cursor: "pointer",
              }}
            >
              <span>{record?.stock - record?.sellproduct}</span>{" "}
              <span>
                {record?.ministock < record?.stock - record?.sellproduct ? (
                  <FaArrowAltCircleUp />
                ) : (
                  <FaArrowAltCircleDown />
                )}
              </span>{" "}
            </span>
          </Space>
        );
      },
    },
    {
      title: "Status",
      key: "status",
      render: (_, record) => (
        <Button
          type={record.status === "Active" ? "primary" : "danger"}
          onClick={() => handleStatusToggle(record)}
        >
          {record.status === "Active" ? "Active" : "Inactive"}
        </Button>
      ),
    },
    // {
    //   title: "Actions",
    //   key: "actions",
    //   render: (_, record) => (
    //     <Space>
    //       <Button
    //         type="text"
    //         icon={<Eye size={16} />}
    //         onClick={() => {
    //           setCurrentProduct(record);
    //           setDetailModalVisible(true);
    //         }}
    //       />
    //       <Button
    //         type="text"
    //         icon={<Edit size={16} />}
    //         onClick={() => handleEdit(record)}
    //       />
    //       <Button
    //         type="text"
    //         danger
    //         icon={<Trash2 size={16} />}
    //         onClick={() => {
    //           setCurrentProduct(record);
    //           setDeleteModalVisible(true);
    //         }}
    //       />
    //     </Space>
    //   ),
    // },
  ];

  return (
    <div className="p-0">
      <Card>
        <div className="mb-6">
          <Title level={3}>Stock Management</Title>
          <div className="d-flex justify-content-between">
            <Input
              placeholder="Search products..."
              prefix={<Search size={16} />}
              value={searchText}
              onChange={(e) => handleSearch(e.target.value)}
              className="mb-4"
              allowClear
              style={{ width: "30%" }}
            />
          </div>

          <Table
            columns={columns}
            dataSource={filteredProducts}
            rowKey="_id"
            loading={loading}
            pagination={{
              pageSize: 10,
              //showSizeChanger: true,
            }}
            scroll={{ x: "max-content" }}
            components={{
              header: {
                cell: (props) => (
                  <th
                    {...props}
                    style={{
                      ...props.style,
                      background: "linear-gradient(#5C870C,#5C870C,#5C870C)",
                      color: "white",
                    }}
                  />
                ),
              },
            }}
          />
        </div>
      </Card>

      {/* StockModel */}
      <Modal
        title={`Update Stock for ${selectedProduct?.productname}`}
        open={isModalOpen}
        onCancel={handleCloseModal}
        footer={null}
      >
        <div className="d-flex justify-content-between">
          <p>
            <strong>Remaining Stock:</strong>{" "}
            {selectedProduct?.stock - selectedProduct?.sellproduct}
          </p>
          <p>
            <strong>Min Stock:</strong> {selectedProduct?.ministock}
          </p>
          <Button icon={<FileSpreadsheet size={16} />} onClick={exportToExcel}>
            Export Excel
          </Button>
        </div>

        <h4>Stock History</h4>
        {AllStock.length > 0 ? (
          <StockHistoryTable stockHistory={AllStock} />
        ) : null}

        <Form form={form} onFinish={handleStockUpdate} layout="vertical">
          <Form.Item
            label="Stock Name"
            name="stockName"
            rules={[
              { required: true, message: "Please input the stock name!" },
            ]}
          >
            <Input placeholder="Enter stock name" />
          </Form.Item>

          <Form.Item
            label="Added Stock"
            name="addedStock"
            rules={[
              { required: true, message: "Please input the stock quantity!" },
            ]}
          >
            <InputNumber
              placeholder="Enter stock quantity"
              style={{ width: "100%" }}
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Update Stock
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Stock;
