import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  items1: [],
  totalItems: 0,
  uniqueItems: 0,
  subtotal1: 0,
  total1: 0,
};


const calculateTotals = (items1) => {
  const subtotal1 = items1.reduce((sum, item) => {
    const selectedWeight = item.selectedWeight || item.weight;
    const price = selectedWeight?.price || 0;
    const discount = selectedWeight?.discount || item?.discount || 0;
    const discountedPrice = price - (price * (discount / 100));
    
    return sum + discountedPrice * item.quantity;
  }, 0);

  const total1 = subtotal1; 
  return { subtotal1, total1 };
};

const cartSlice1 = createSlice({
  name: 'cart1',
  initialState,
  reducers: {
    addToCarts1: (state, action) => {
      const existingItem = state.items1.find(item => item._id === action.payload._id);
      if (existingItem) {
        existingItem.quantity += 1;
      } else {
        state.items1.push({ ...action.payload, quantity: 1 });
        state.uniqueItems += 1;  
      }
      state.totalItems = state.items1.reduce((total, item) => total + item.quantity, 0);
      const totals = calculateTotals(state.items1);
      state.subtotal1 = totals.subtotal1;
      state.total1 = totals.total1;
    },
    addToCart1: (state, action) => {
      const { _id, selectedWeight, quantity = 1 } = action.payload;
      const existingItemIndex = state.items1.findIndex(item => 
        item._id === _id && 
        item.selectedWeight?.value === selectedWeight?.value &&
        item.selectedWeight?.units === selectedWeight?.units
      );
      
      if (existingItemIndex !== -1) {
        state.items1[existingItemIndex].quantity += quantity;
      } else {
        state.items1.push({ ...action.payload, quantity });
        state.uniqueItems += 1;
      }
      
      state.totalItems = state.items1.reduce((total, item) => total + item.quantity, 0);
      const totals = calculateTotals(state.items1);
      state.subtotal1 = totals.subtotal1;
      state.total1 = totals.total1;
    },
    
    removeFromCart1: (state, action) => {
      const index = state.items1.findIndex(
        item => item._id === action.payload._id && item.selectedWeight?.id === action.payload.selectedWeight?.id
      );
      
      if (index !== -1) {
        if (state.items1[index].quantity > 1) {
          state.items1[index].quantity -= 1;
        } else {
          state.items1.splice(index, 1);
          state.uniqueItems -= 1; 
        }
      }

      state.totalItems = state.items1.reduce((total, item) => total + item.quantity, 0);
      const totals = calculateTotals(state.items1);
      state.subtotal1 = totals.subtotal1;
      state.total1 = totals.total1;
    },
    
    clearItem1: (state, action) => {
      const index = state.items1.findIndex(
        item => item._id === action.payload._id && item.selectedWeight?.id === action.payload.selectedWeight?.id
      );
      
      if (index !== -1) {
        state.items1.splice(index, 1);
        state.uniqueItems -= 1;
      }

      state.totalItems = state.items1.reduce((total, item) => total + item.quantity, 0);
      const totals = calculateTotals(state.items1);
      state.subtotal1 = totals.subtotal1;
      state.total1 = totals.total1;
    },
    
    clearCart1: (state) => {
      state.items1 = [];
      state.totalItems = 0;
      state.uniqueItems = 0;
      state.subtotal1 = 0;
      state.total1 = 0;
    },
  },
});

export const { addToCart1,addToCarts1, removeFromCart1, clearItem1, clearCart1 } = cartSlice1.actions;
export default cartSlice1.reducer;
